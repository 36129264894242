@import "globals";



div.home {
  display: flex;
  flex-wrap: wrap;
  font-size: 3em;
  // height: calc(100% - 3em);
  // width: 50%;
  // height: 100%;
  padding-top: 5em;
  padding-bottom: 2em;
  justify-content: center;
  align-items: center;
  iframe {
    font-size: 30em;
  }

 

  // @media screen and (min-width: 601px) {
  //   div {
  //     font-size: 1em;
  //   }
  // }

  // @media screen and (max-width: 600px) {
  //   div {
  //     font-size: 0.5em;
  //   }
  // }








}