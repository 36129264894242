@import "globals";

img.header{
  height: 3em !important;
}

div.info {
  display: flex;
  flex-wrap: wrap;
  font-size: 3em;
  // height: calc(100% - 3em);
  width: 100%;
  height: 100%;
  padding-top: 3em;
  padding-bottom: 2em;



  @media screen and (min-width: $pc-resolution) {
    div {
      font-size: 1em;
    }
  }

  @media screen and (max-width: $phone-resolution) {
    div {
      font-size: 0.5em;
    }
    span.photos {

      display: block;

      img {
        width: calc(100% - 20em);
        // padding-top: 50%;
        max-width: 700px;
        height: 80%;
      }

      p{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2em;
        width: 100%;
  
      }
      
    }
  }

  > div {
    width: 100%;
    height: 100%;
    position: relative; 
    // padding-top: 3em;
    // padding-bottom: 2em;
  > span {

    display: flex;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    width: 100%;
    // height: 100px;
    position: relative; 

    >div {
      padding: 20px ;
    }

    h1 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70%;
      padding: 1em;

      
    }

    p{
      display: flex;
      // justify-content: center;
      align-items: center;
      width: 70%;

    }

    img {
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 25em ;
      // width: 70%;
      // width: 100%;
      object-fit: cover;

      position: relative; 
    }

  }
}


}