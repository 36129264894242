@import "globals";

div.gig-list {
  height: calc(100% - 13em);
  overflow-y: scroll;

  table {
    padding-top: 5em;
    padding-bottom: 5em;
    caption {
      background-color: white;
      background-color: rgba(255, 255, 255, 0.9);
      color: #8f652e;
    }
    width: 100%;
    // height: 90%;
    // background-color: rgba(255, 255, 255, 0.801);
    color: rgba(227, 222, 231, 0.699);
    // font-family: verdana, sans-serif;
    font-size: 3em;
    text-align: center;

    th {
      color: #8f652e;
    }
    td {
      padding: 0.2em;
    }

    a {
      margin: 15px;
    }

    Link {
      color: red;
    }
    @media screen and (min-width: $pc-resolution) {
      img {
        width: 30em;
      }
    }

    @media screen and (max-width: $phone-resolution) {
      img {
        width: 22em;
        // width: calc(100vw - 480px);
      }
      font-size: 1.8em;
    }
  }
  /* unvisited link */
a:link {
  color: slategray;
}

/* visited link */
a:visited {
  color: lightcoral;
}

/* mouse over link */
a:hover {
  color: hotpink;
}

/* selected link */
a:active {
  color: blue;
}
}
