@import "globals";

div.footer {
  background-color: black;
  position: fixed;
  bottom: 0;
  left: 0;
  width: calc(100% - 1em);
  font-size: 4em;
  padding: .5em;
  display: flex;
  // justify-content: space-around;
  a {
    color: $snow-white;
    margin: 0.5em;
  }

  @media screen and (max-width: $phone-resolution) {

    font-size: 3em;
  }

}
div.copyright{
  color: $snow-white;
  @media screen and (min-width: 601px) {
    
    font-size: small;
    padding-left: calc(100% - 51em);
  }
  @media screen and (max-width: $pc-resolution) {
    height: 0px;
     visibility: hidden;
  }
}