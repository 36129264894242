@import "globals";



div.home {
  display: flex;
  flex-wrap: wrap;
  font-size: 3em;
  // height: calc(100% - 3em);
  width: 100%;
  height: 100%;
  padding-top: 3em;
  padding-bottom: 2em;

  .logo{
    img{
      padding: 1em;
      width: 7em;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    

  }

  .news{
    list-style-type:circle;
    width: 70%;
    // padding-right: 3%;
  }

  .font{
    img{
      // padding: 1em;
      width: 35em;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    
  }

  @media screen and (min-width: $pc-resolution) {
    div {
      font-size: 1em;
    }
  }

  @media screen and (max-width: $phone-resolution) {
    div {
      font-size: 0.5em;
    }
  }

  > div {
    width: 100%;
    height: 100%;
    position: relative; 
    // padding-top: 3em;
    // padding-bottom: 2em;
  > span {

    display: flex;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    width: 100%;
    // height: 100px;
    position: relative; 


    h1 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70%;
      padding: 1em;

      
    }

    p{
      display: flex;
      // justify-content: center;
      align-items: center;
      width: 70%;

    }

    img {
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      // height: 500px ;
      width: 70%;
      // width: 100%;
      object-fit: cover;

      position: relative; 
    }

  }
}


}


div.stats {
  display: flex;
  flex-wrap: wrap;
  font-size: 3em;
  // height: calc(100% - 3em);
  width: 100%;
  height: 100%;
  padding-top: 3em;
  padding-bottom: 2em;


  @media screen and (min-width: $pc-resolution) {
    div {
      font-size: 1em;
    }
  }

  @media screen and (max-width: $phone-resolution) {
    div {
      font-size: 0.5em;
    }
  }

  > div {
    width: 100%;
    height: 100%;
    position: relative; 
  > span {
    font-size: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    width: 100%;
    position: relative; 
  }
}

}