@import "globals";

h1 {
  display: flex;
  flex-wrap: wrap;
  font-size: 5em;
  height: calc(100% - 3em);
  padding-top: 3em;
  padding-bottom: 2em;
  justify-content: center;
  align-items: center;
  color: $snow-white;
}

.facebook {

  // display: flex;
  // flex-wrap: wrap;
  // font-size: 3em;
  // height: calc(100% - 3em);
  // padding-top: 3em;
  // padding-bottom: 2em;
  // justify-content: center;
  // align-items: center;
  div{
    width: 500px;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  button{
    visibility: hidden;
  }
}
.youtube {
  margin-left: 15% !important; 
  margin-right: 15% !important;
}


.flex-container {
  // top: 500;
  display: flex;
  flex-wrap: wrap;
  font-size: 3em;
  height: calc(100% - 3em);
  padding-top: 3em;
  padding-bottom: 2em;
  justify-content: center;
  align-items: center;
  p {
    display: flex;
    flex-wrap: wrap;
    // font-size: 5em;
    // height: calc(100% - 3em);
    // padding-top: 3em;
    // padding-bottom: 2em;
    justify-content: center;
    align-items: center;
    color: $snow-white;
  }

  @media screen and (min-width: $pc-resolution) {
    div {
      overflow: hidden;
      
      img{
        width: 15em;
        // max-height: 10em;
        // height: 15em;
      }
      iframe{
        // height: 15em;
        width: 35em;
      }
      
    }
  }

  // @media screen and (max-width: $phone-resolution) {
  //   div {
  //     img{
  //       width: 10em;
  //     }
  //     iframe{
  //       height: 7em;
  //       width: 10em;
  //     }
      
  //   }
  // }

}

.flex-container > div {
  background-color: #f1f1f1;
  margin: 5px;
  padding: 5px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(121, 18, 18);
  margin: auto
 
  img{
      width: 20em;
     
    }
}

