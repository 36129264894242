@import "globals";

nav.menu {
  background-color: black;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: calc(100% - 1em);
  font-size: 4em;
  padding: .5em;
  display: flex;
  justify-content: space-around;

  a {
    color: $snow-white;
  }

  .movedown{
    margin-top: 0.20em;
  }
}


  html{
    background-color: darken($color: #111111, $amount: 0);
    background-image: url('../img/Webpage.jpg');
    // font-family: "Comic Sans MS", cursive, sans-serif;
    
  }
  body,
  div#react-container,
  div.app {
    padding: 0;
    margin: 0;
    height: max-content;
  }
  
  
  body {
    font-size: 55%;
  }
  
  h1, label {
    margin: 0
  }
  
  div.app {
    // background-image: url('/img/bsot_background.jpg');
    // background-color: darken($color: #111111, $amount: 0);
    background-size: cover;
    background-position: center;
    
  }
  

