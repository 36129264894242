@import "globals";


.flex-container {
  // top: 500;
  display: flex;
  flex-wrap: wrap;
  font-size: 3em;
  height: calc(100% - 3em);
  padding-top: 3em;
  padding-bottom: 2em;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 601px) {
    div {
      img{
        width: 15em;
      }
      iframe{
        height: 15em;
        width: 20em;
      }
      
    }
  }

  @media screen and (max-width: 600px) {
    div {
      img{
        width: 10em;
      }
      iframe{
        height: 7em;
        width: 10em;
      }
      
    }
  }

}

.flex-container > div {
  background-color: #f1f1f1;
  margin: 5px;
  padding: 5px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(121, 18, 18);
  margin: auto
 
  img{
      width: 20em;
     
    }
}

.flex-container1 > div {
  padding: 1em;
  img{
    margin: 5px;
    padding: 5px;
    background-color: #f1f1f1;
  }
  // font-size: 3em;
  align-items: center;
  color: #f1f1f1;
  margin: auto
 
  img{
      width: 20em;
    }
}

.flex-container1{
// top: 500;
margin: 2em;
display: flex;
flex-wrap: wrap;
font-size: 3em;
height: calc(100% - 3em);
padding-top: 3em;
padding-bottom: 2em;
justify-content: center;
// align-items: center;

@media screen and (min-width: $phone-resolution) {
  div {
    img{
      width: 15em;
    }
    iframe{
      height: 15em;
      width: 20em;
    }
    
  }
}

@media screen and (max-width: $phone-resolution) {
  div {
    img{
      width: 10em;
    }
    iframe{
      height: 7em;
      width: 10em;
    }
    
  }
}

/* unvisited link */
a:link {
  color: slategray;
}

/* visited link */
a:visited {
  color: lightcoral;
}

/* mouse over link */
a:hover {
  color: hotpink;
}

/* selected link */
a:active {
  color: blue;
}

}